import React, { useState, useEffect } from "react"
import {Link} from "gatsby"
import {useStaticQuery, graphql} from "gatsby"
import { update } from "lodash-es"

function HearFromYouForm(props){
    const [idToken, setIdToken] = useState('');
    
    useEffect(() => {
        const fetchIdToken = async () => {
          const response = await fetch('https://prostate.radnetimaging.com/mri-form/', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              action: 'get-token',
            }),
          });
    
          const data = await response.json();
          setIdToken(data.idToken);
        };
    
        fetchIdToken();
    }, []);

    let locationData = useStaticQuery(
        graphql`
            query {
                allNodeCenterLocation {
                    edges {
                        node {
                            title
                            field_location_procedures
                        }
                    }
                }
            }
        `
    )

    let locations = locationData.allNodeCenterLocation.edges

    let tokenRef = React.createRef()
    let nameRef = React.createRef()
    let emailRef = React.createRef()
    let centerRef = React.createRef()
    let phoneRef = React.createRef()
    let [service, setService] = useState('prostate-mri')
    let messageRef = React.createRef()
    let [submitted, setSubmitted] = useState(false)


    function validate(e){
        e.preventDefault()

        let errors = []
        if(nameRef.current.value.length <= 2) errors.push("Fix Name");
        if(emailRef.current.value === '') errors.push("Fix Email");
        if(phoneRef.current.value === '') errors.push("Fix Email");
        if(centerRef.current.value === 'select') errors.push("Select a center");
        if(messageRef.current.value.length <= 2 ) errors.push("Please write a message");

        //if no errors
        if(errors.length == 0){
            handleSubmit()
        }else {
          let out = ''
          errors.forEach((error)=> out += error + "\n" )
          alert(`Please make sure all fields are filled out correctly:\n${out}`)
        }
    }

    function handleSubmit(){
      //All good? Submit!!
        setSubmitted(true)

        let dataToSend = {
            token: tokenRef.current.value,
            name: nameRef.current.value,
            email: emailRef.current.value,
            phone: phoneRef.current.value,
            service: 'tulsa-pro',
            source: 'tulsa-pro',
            action: 'send-form',
            center: centerRef.current.value,
            message: messageRef.current.value
        }

        console.log(dataToSend);

         fetch('https://prostate.radnetimaging.com/mri-form/',{
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json'
             },
             method: "POST",
             credentials: 'include',
             body: JSON.stringify(dataToSend)
         }).then(res => res.json())
         .then(res => { //Success
          })
         .catch(err => console.log(err))

    }

    return (
        <div className="hearfromyou mt5">
            <h2>We Want To Hear From You</h2>
            <div className="flex">
                <div className="contact-card">
                    <h2>Enhanced Prostate Screening (EPS)</h2>
                    <p>Enhanced Prostate Screening (EPS) leverages DeepHealth's, FDA cleared, AI-powered prostate solution to deliver early and accurate cancer detection.</p>
                    <br/>
                    <div className="form-inner">
                        <p>Arizona: <a href="tel:+18663574351" className="b">(866) 357-4351</a></p>
                        <p>Southern California: <a href="tel:+18663574355" className="b">(866) 357-4355</a></p>
                        <p>Texas: <a href="tel:+18663574349" className="b">(866) 357-4349</a></p>
                    </div>
                </div>
                
                <div className="contact-card">
                    <h2>Prostate MRI</h2>
                    <div className="form-inner">
                        <p>Arizona: <a href="tel:+16028923640" className="b">(602) 892-3640</a></p>
                        <p>Fresno: <a href="tel:+15594744292" className="b">(559) 474-4292</a></p>
                        <p>Coachella Valley: <a href="tel:+17608434028" className="b">(760) 843-4028</a></p>
                        <p>Victor Valley: <a href="tel:+17609991041" className="b">(760) 999-1041</a></p>
                        <p>Inland Empire: <a href="tel:+19094500651" className="b">(909) 450-0651</a></p>
                        <p>Antelope Valley: <a href="tel:+16612463682" className="b">(661) 246-3682</a></p>
                        <p>Long Beach: <a href="tel:+15622996245" className="b">(562) 299-6245</a></p>
                        <p>Los Angeles: <a href="tel:+13103096711" className="b">(310) 309-6711</a></p>
                        <p>San Fernando Valley: <a href="tel:+18184807261" className="b">(818) 480-7261</a></p>
                        <p>San Gabriel Valley: <a href="tel:+16262083520" className="b">(626) 208-3520</a></p>
                        <p>Temecula: <a href="tel:+19512386043" className="b">(951) 238-6043</a></p>
                        <p>Ventura: <a href="tel:+18052998835" className="b">(805) 299-8835</a></p>
                        <p>Orange North: <a href="tel:+16573042330" className="b">(657) 304-2330</a></p>
                        <p>Orange South: <a href="tel:+17147841689" className="b">(714) 784-1689</a></p>
                        <p>Bakersfield: <a href="tel:+16613620142" className="b">(661) 362-0142</a></p>
                    </div>
                </div>
                
                <div className="contact-card">
                    <h2>TULSA Procedure</h2>
                    <p>TULSA Procedure Phone: <a className="b" href="tel:+18184807266">(818) 480-7266</a></p>
                    <p>TULSA Procedure Fax: <a className="b" href="tel:+18188275152">(818) 827-5152</a></p>
                    
                    <form className={`${submitted ? 'hide': 'show'} mt3 mb4`} onSubmit={validate}>
                        <input type="hidden" name="idToken" id="idToken" value={idToken} ref={tokenRef}/>
                        <div className='form-inner'>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l lbl"><label htmlFor="name" className="item">Name: </label></p>
                                <p className="fl-l w-75-l"><input className={``} ref={nameRef} name="name" type="text" required placeholder="First Name, Last Name" /></p>
                            </fieldset>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l lbl"><label htmlFor="email" className="item">Email: </label></p>
                            <p className="fl-l w-75-l"><input className={``} ref={emailRef} name="email" type="email" placeholder="example@address.com" required /></p>
                            </fieldset>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l lbl"><label htmlFor="phone" className="item">Phone: </label></p>
                            <p className="fl-l w-75-l"><input className={``} ref={phoneRef} name="phone" type="text" placeholder="000-000-0000" required /></p>
                            </fieldset>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l lbl"><label htmlFor="location" className="item">Location: </label></p>
                                <p className="fl-l w-75-l">
                                <select name="location" ref={centerRef} defaultValue="select" required>
                                    <option disabled value="select">--Select--</option>
                                    {locations.filter(item => {
                                        let tulsa = false;
                                        let {field_location_procedures} = item.node;
                                        if (field_location_procedures) {
                                            field_location_procedures.forEach(it => {
                                                if (item.node.title === 'Liberty Pacific Advanced Imaging | West Hills' || item.node.title === 'Arizona Diagnostic Radiology Park Central' || item.node.title === 'WaveImaging Saddleback') {
                                                    tulsa = true;
                                                }
                                            });
                                        }
                                        return tulsa;
                                    }).map((item, idx) => {
                                        let {title} = item.node;
                                        return <option key={idx} value={`${title}`}>{title}</option>;
                                    })}
                                </select>
                                </p>
                            </fieldset>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l lbl"><label className="item">Message: </label></p>
                                <p className="fl-l w-75-l"><textarea className={``} ref={messageRef} required name="text" ></textarea></p>
                            </fieldset>
                        </div>
                        <div className="mt4 text-center">
                            <button className={`submit-form mr0 mr2-l mb3 mb0-l`} disabled={`${submitted ? 'disabled' : ''}`} type="submit">TULSA Procedure Request</button>
                        </div>
                    </form>
                    <div className={`${submitted ? 'show':'hide'}`}>
                        Message Sent!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HearFromYouForm